import React from 'react';
import certificateData from 'data/certificateData.js';
import DataTable from 'components/DataTable'
import { AWS_ACCOUNT_DISPLAY } from 'config/aws_accounts.js';

export default function ACMTable(props) {

   const stateTemplates = {
      cookieVersions: {
         activeColumns: 1, //the columns that are displayed on the table
         filters: 1, //'only show certs in use', 'has expired since', 'expiring within', 'search'
         expirationRange: 1, //low, medium, high alert ranges
         expirationColors: 1.01, //highlight colors for low, medium, high alert
      },

      filters: { //if true, row must meet that condition
         'isInUse': true,
         'expirationRange': {
            'from': 7,
            'to': 60
         },
         'searchValue': '',
         'showAccounts': AWS_ACCOUNT_DISPLAY,
         activeColumns: {
            'Certificate ARN': true,
            'Issuer': false,
            'Expiration': true,
            'Upload Date': false,
            'Renewal Eligibility': false,
            'Serial': false,
            'Status':  false,
            'In Use By': true,
            'AWS Account': true,
         },
      },

      expirationRange: {
         'highAlertRange': 10,
         'mediumAlertRange': 20,
         'lowAlertRange': 30,
         'alertMetric': 'days',
      },

      expirationColors: {
         'highAlertRange': {
            r: '248',
            g: '215',
            b: '218',
            a: '1',
         },
         'mediumAlertRange': {
            r: '255',
            g: '223',
            b: '205',
            a: '1',
         },
         'lowAlertRange': {
            r: '255',
            g: '243',
            b: '205',
            a: '1',
         },
      },
   }

   //used to turn activeColumns into dataFieldNames for columns in DataTable.js
   //because of different formatting
   const columnsToDataFields = {
      'Certificate ARN': 'CertificateArn',
      'Issuer': 'Issuer',
      'Expiration': 'Expiration',
      'Upload Date': 'UploadDate',
      'Renewal Eligibility': 'RenewalEligibility',
      'Serial': 'Serial',
      'Status': 'Status',
      'AWS Account': 'AWSAccount',
      'In Use By': 'InUseBy',
   };

   return (
      <DataTable
         tableTitle="ACM Certificates Expired Within 7 Days and Expiring Within 60 Days"
         tableID="ACMTable"
         stateTemplates={stateTemplates}
         dataRangeFunction={certificateData.ACMCertsRange}
         columnsToDataFields={columnsToDataFields}
         serverDropdownText={"SHOW USE"}
         serverDropdownType={'ACM'}
         serverUseField={"InUseBy"}
         saveCookies={props.saveCookies}
      />
   )
}
