import React from 'react';
import styles from './DataCheckbox.module.scss';

export default function DataCheckbox({name, checked, filters, setFilters, filterName}) {

   return (
      <label className={styles.label}>
         {name}
         <input
            type="checkbox"
            name="name"
            checked={checked}
            onChange={() => setFilters({...filters, [filterName]: !filters[filterName]})}
         />
      </label>

   );
}
