import React, { useState, useEffect, useRef } from 'react';
import styles from './DataTableHeader.module.scss';

import ColumnDropdownMenu from 'components/ColumnDropdownMenu';

export default function DataTableColumnHeader({ column, className }) {

   const colHeader = column.render('Header');

   const expirationColumn = useRef();

   const firstThreeChars = colHeader.slice(0, 3);
   const useOnContextMenu = (firstThreeChars !== 'ACM' && firstThreeChars !== 'IAM');

   //once page renders, click on expirationColumn to auto-sort it from expiring the soonest to oldest
   useEffect(() => {
      if (expirationColumn?.current) {
         expirationColumn.current.click();
      }
   }, [])

   return (
      <th
         {...column.getHeaderProps((column.getSortByToggleProps()))}
         className={`${className} ${styles.box}`}
         ref={colHeader === 'Expiration' ? expirationColumn : null}
      >
         {colHeader}
         <span>
            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
         </span>
      </th>
   );
}
