import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './DataFilterChecklistDropdown.module.scss';

export default function DataAccountDropdown({filters, setFilters, filterName, menuText}) {

   const [anchorEl, setAnchorEl] = useState(null);

   const openAccountDropdownMenu = event => setAnchorEl(event.currentTarget);

   const handleClose = () => setAnchorEl(null);

   const onCheckboxClick = key => {
      if (filters?.[filterName]) {
         const filter = filters[filterName];
         const flippedVal = !filter[key];

         setFilters({
            ...filters,
            [filterName]: {
               ...filter,
               [key]: flippedVal,
            }
         })
      }

   }

   console.log('filters?.showAccounts = ', filters)

   return (
      <>
         <div onClick={openAccountDropdownMenu} className={styles.label}>{menuText}</div>
         <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            {
               filters?.[filterName]
                  ? Object.entries(filters[filterName]).map(([key, checked]) => (
                     <MenuItem
                        onClick={() => onCheckboxClick(key)}
                        className={styles.item}
                     >
                        {key}
                        <input
                           type="checkbox"
                           name="name"
                           checked={checked}
                        />
                     </MenuItem>
                  ))
                  : null
            }
         </Menu>
      </>
   );
}
