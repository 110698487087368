import React from 'react';
import styles from './App.module.scss';
import { useAuth } from 'utils/auth.js';
import { withAuth } from '@okta/okta-react';

import IAMTable from 'components/IAMTable';
import ACMTable from 'components/ACMTable';
import RemoveCookie from 'components/RemoveCookie/RemoveCookie.js';
import Header from 'components/Header/Header.js';

const Dashboard = withAuth(({auth}) => {

   const [authenticated, user] = useAuth(auth);

   console.log('auth = ', auth, 'authenticated = ', authenticated, 'user = ', user)

   if (authenticated !== null && !authenticated) {
      auth.login('/');
   }

   const notAuthenticatedElement = authenticated === null ? <div>Not loaded</div> : <div>Don't have access</div>;

   return (
      authenticated !== null && authenticated ?
      <>
         <Header />
         <div className={styles.App}>
            <IAMTable saveCookies/>
            <ACMTable saveCookies/>
         </div>
      </>
      : notAuthenticatedElement
   )

})

export default Dashboard;
