import certAccounts from './AllAccountData.json';
import moment from 'moment';

//takes all certificates from a certain field in all accounts passed in and
//adds the name of the AWS Account to the certificate and returns it in one array
const flattenCerts = (accounts, fieldName) => {
   const certs = [];

   for (let acct of accounts) {
      for (let cert of acct[fieldName]) {
         certs.push({ ...cert, 'AWSAccount': acct.name});
      }
   }

   return certs;
}
//takes flattenedCerts and changes key name in fieldNameDict to it's value
//returns new array of cert objects with changed key names
const changeFieldNames = (flattenedCerts, fieldNameDict) => {
   for (let cert of flattenedCerts) {
      for (let [field, newField] of Object.entries(fieldNameDict)) {
         if (cert[field]) {
            const value = cert[field];
            delete cert[field];
            cert[newField] = value;
         }
      }
   }

   // console.log('flC = ', flattenedCerts)
   return flattenedCerts;
}

//filters certs by date range
//expiredSince: has expired since N days previous
//willExpireWithin: will expire within the next N days
const filterDayRange = (certs, expiredSince, willExpireWithin) => {
   const startRange = moment().subtract(expiredSince, 'days');
   const endRange = moment().add(willExpireWithin, 'days');

   return certs.filter(
      cert => {

         const isBetween = moment(cert.Expiration).isBetween(startRange, endRange);
         return isBetween
      }
   );
}

export default {
   'IAMCertsRange': ({from, to}) => filterDayRange(flattenCerts(certAccounts, 'expired_iam_certs_report'), from, to),
   'ACMCertsRange': ({from, to}) => {
      // console.log('certificateData, certAccounts = ', certAccounts)
      const flattenedCerts = flattenCerts(certAccounts, 'acm_certificate_report');
      // console.log('flattenedCerts = ', flattenedCerts);

      const certsWithNewFieldNames = changeFieldNames(flattenedCerts,
         {
            'NotAfter': 'Expiration',
            'NotBefore': 'UploadDate',
         }
      );
      // console.log('certsWithNewFieldNames = ', certsWithNewFieldNames);

      const certsWithinRange = filterDayRange(certsWithNewFieldNames, from, to);
      // console.log('certsWithinRange = ', certsWithinRange);

      return certsWithinRange;
   }
}
