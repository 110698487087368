import React, { useState } from 'react';
import styles from './DataTableSettings.module.scss';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ExpirationRangeInput from 'components/ExpirationRangeInput';
import ColorPicker from 'components/ColorPicker';

export default function DataTableSettings(props) {
   const {
      expirationRangeHook,
      expirationColorsHook
   } = props;

   const [anchorEl, setAnchorEl] = useState({
      menu: null,
      colorPicker: null,
   });

   const [colorPicker, setColorPicker] = useState({
      display: false,
      expirationField: '',
   })

   const openMenu = event => setAnchorEl({...anchorEl, menu: event.currentTarget});
   const openColorPicker = event => {
      console.log('e = ', event.currentTarget)
      setAnchorEl({...anchorEl, colorPicker: event.currentTarget})
   }


   const handleClose = (fieldName) => setAnchorEl({...anchorEl, [fieldName]: null});

   const handleExpirationRangeClick = (e, type, expirationField) => {

      if (type && type === 'close')
         setColorPicker({...colorPicker, display: false});
      else {
         setColorPicker({...colorPicker, expirationField , display: !colorPicker.display});
         openColorPicker(e);
      }

   }


   const expirationRangeTypes = [
      {displayName: 'High Alert', expirationField: 'highAlertRange'},
      {displayName: 'Medium Alert', expirationField: 'mediumAlertRange'},
      {displayName: 'Low Alert', expirationField: 'lowAlertRange'},
   ]

   return (
      <>
         <i className={`g72-gear2 ${styles.settings}`}  onClick={openMenu}></i>
         <Menu
            id="simple-menu"
            anchorEl={anchorEl.menu}
            keepMounted
            open={Boolean(anchorEl.menu)}
            onClose={() => handleClose('menu')}
         >
            <MenuItem className={styles.title}>
               <div className={styles.title}>
                  Expiration Highlighting
               </div>
            </MenuItem>
            
            {expirationRangeTypes.map( expRange => (
               <ExpirationRangeInput
                  onClick={e => handleExpirationRangeClick(e, '', expRange.expirationField)}
                  {...expRange}
                  {...props}
               />
            ))}
         </Menu>

         <ColorPicker
            onCoverClick={e => handleExpirationRangeClick(e, 'close')}
            expirationColorsHook={expirationColorsHook}
            expirationField={colorPicker.expirationField}
            anchorEl={anchorEl.colorPicker}
            onClose={() => handleClose('colorPicker')}
         />
      </>

   )
}

/* <ExpirationRangeInput

   color={expirationColors.highAlertColor}

/>
<ExpirationRangeInput
   name="Medium Alert"
   color={expirationColors.mediumAlertColor}
   expirationField="mediumAlertRange"
   expirationRangeHook={[expirationRange, setExpirationRange]}
   expirationColorsHook={[expirationColors, setExpirationColors]}
/>
<ExpirationRangeInput
   name="Low Alert"
   color={expirationColors.lowAlertColor}
   expirationField="lowAlertRange"
   expirationRangeHook={[expirationRange, setExpirationRange]}
   expirationColorsHook={[expirationColors, setExpirationColors]}
/> */
