import React from 'react'

import Dashboard from 'components/Dashboard';
import { HashRouter, BrowserRouter as Router, Route } from "react-router-dom";
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';

import { useAuth } from 'utils/auth.js';

import { OKTA_ISSUER, OKTA_CLIENT_ID } from 'config/okta.js';

const App = () => {

   console.log('OKTA_ISSUER = ', OKTA_ISSUER, 'OKTA_CLIENT_ID = ', OKTA_CLIENT_ID)
   console.log('window.location.origin = ', window.location.origin)

   return (
      <Router

      >
         <Security
            issuer={OKTA_ISSUER}
            client_id={OKTA_CLIENT_ID}
            redirect_uri={`${window.location.origin}/oAuthCallback`}

         >
            <Route exact path="/" component={Dashboard} />
            <Route path="/oAuthCallback" component={ImplicitCallback} />
         </Security>
      </Router>
   );
}

export default App;
