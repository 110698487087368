import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import moment from 'moment';

const expires = moment().add(100, 'years').toDate();
const defaultCookieOptions = { path: '/', expires: expires }; //maxAge: maxDate
const cookieID = (key, tableID) => `${key}-${tableID}`;

//generates columns array for using in useTable
function generateColumns(activeColumns, columnsToDataFields, tableTitle) {
   const columnNames = [];
   const dataFieldNames = {}; //used to match columns with data field names from provided data

   for (const [name, active] of Object.entries(activeColumns)) { //adding active column fields to columnNames and dataFieldNames
      if (active) {
         columnNames.push(name);
         dataFieldNames[name] = columnsToDataFields[name];
      }
   }

   const columns = [
      {
         Header: tableTitle,
         columns: columnNames.map( name => {
            return ({
               Header: name,
               accessor: dataFieldNames[name],
               sortType: 'basic',
            }) }
         )
      }
   ];

   //if header has no columns, make an empty one show table has something to render
   if (columns[0].columns.length === 0) {
      columns[0].columns.push({
         Header: ' ',
         accessor: ' ',
      });
   }

   return columns;
}

//creates a new stateTemplates object with key names modified with tableID
function transformStateTemplateKeys([stateTemplates, tableID]) {
   const modifiedST = {};

   for (let key of Object.keys(stateTemplates)) {
      modifiedST[cookieID(key, tableID)] = stateTemplates[key];
   }
   return [modifiedST, tableID];
}

function removeOldCookies([stateTemplates, tableID]) {

   const tableCookieVersions = cookieID('cookieVersions', tableID); //make cookieID for cookieVersions for table
   const newCookieVersions = stateTemplates[tableCookieVersions]; //latest cookie versions

   let loadedCookieVersions = Cookies.get()[tableCookieVersions]; //cookie versions from cookies on page
   if (loadedCookieVersions) {
      loadedCookieVersions = JSON.parse(loadedCookieVersions);
   }

   const isLoadedCookieVersionsEmpty = loadedCookieVersions ? Object.keys(loadedCookieVersions).length > 0 : false;

   let removedCookie = false;
   if (isLoadedCookieVersionsEmpty) {
      for (let key of Object.keys(newCookieVersions)) { //go through all keys of cookie versions

         if (newCookieVersions[key] !== loadedCookieVersions[key]) { //if version number of new is different from existing cookie on page
            Cookies.remove(cookieID(key, tableID), {path: ''}); //remove that cookie from page
            removedCookie = true;
         }
      }

   }
   if (removedCookie) {
      Cookies.remove(tableCookieVersions, {path: ''})
   }

   return [stateTemplates, tableID];
}

//if no existing cookies, initializes it
function useInitializeCookies([stateTemplates, tableID]) {
   const cookieIDs = Object.keys(stateTemplates);

   let [cookies, setCookie] = useCookies(cookieIDs);

   for (let key of Object.keys(stateTemplates)) {

      if (!cookies[key]) {
         setCookie(key, stateTemplates[key], defaultCookieOptions)
      }
   }

   //if just initiliazed, then must reload them
   return useCookies(cookieIDs);
}

//filter functions renderData
function filterData(filters, renderData, serverUseField) {

   if (filters.isInUse) {
      renderData = renderData.filter(cert => cert[serverUseField] && cert[serverUseField].length > 0)
   }
   if (filters.searchValue !== '') {
      renderData = renderData.filter(cert => {

         for (let [, value] of Object.entries(cert)) {
            if (value.includes(filters.searchValue)) {
               return true;
            }
         }
         return false;
      })
   }

   if (filters.showAccounts) {
      renderData = renderData.filter(cert => filters.showAccounts[cert.AWSAccount])
   }


   return renderData;
}

export {
   cookieID, defaultCookieOptions, generateColumns, transformStateTemplateKeys, useInitializeCookies, filterData, removeOldCookies
}
