export const AWS_ACCOUNTS = [
   'plus',
   'plus-test',
   'commerce-prod',
   'commerce-test',
   'coresre-test',
   'shared-prod',
   'shared-test',
   'user-services-prod',
   'user-services-test'
];

export const AWS_ACCOUNT_DISPLAY = AWS_ACCOUNTS.reduce( (acctMap, acct) => {
   return {
      ...acctMap,
      [acct]: true
   }
}, {})
