import React from 'react';
import styles from './DataSearchBar.module.scss';

export default function DataSearchBar({filters, setFilters, filterName}) {

   return (
      <div className={`${styles.label} ${styles.container}`}>
         <div style={{order: 1}}>Search:</div>
         <input style={{order: 2}} type="search" value={filters.searchValue} onChange={e => setFilters({...filters, [filterName]: e.target.value})}></input>
      </div>
   )

}
