// export const OKTA_ORG_URL = 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/.well-known/openid-configuration';
const oktaHostDev = 'nike-qa.oktapreview.com';
const oktaHostProd = 'nike.okta.com';

const oktaAuthServerIDDev = 'ausa0mcornpZLi0C40h7';
const oktaAuthServerIDProd = 'aus27z7p76as9Dz0H1t7';

const inProd = window.location.hostname === 'ssl-certificate-dashboard.nde-coresre-test.nikecloud.com';
const oktaHost = inProd ? oktaHostProd :  oktaHostDev;
const oktaAuthServerID = inProd ? oktaAuthServerIDProd : oktaAuthServerIDDev;

export const OKTA_ISSUER = `https://${oktaHost}/oauth2/${oktaAuthServerID}`
export const OKTA_CLIENT_ID = 'nike.reliability.ssl-certificate-dashboard';
