import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Menu from '@material-ui/core/Menu';
import styles from './ColorPicker.module.scss';

export default function ColorPicker(props) {

   const {
      onCoverClick,
      expirationColorsHook: [expirationColors, setExpirationColors],
      expirationField,
      anchorEl,
      onClose,
   }
   = props;

   const colorsOnChangeHandler = color => {
      console.log('color from ', expirationField, ' = ', color)
      setExpirationColors({...expirationColors, [expirationField]: color.rgb})

      // setInternalState({ ...internalState,
      //    expirationColors: {...expirationColors,
      //       [expirationField]: color.rgb
      //    }
      // });
   }

   return (
      <Menu
         id="simple-menu"
         anchorEl={anchorEl}
         keepMounted
         open={Boolean(anchorEl)}
         onClose={onClose}
      >
         <SketchPicker
            color={expirationColors[expirationField]}
            onChange={colorsOnChangeHandler}
            className={styles.sketchPicker}
         />
      </Menu>

   );
}
