import React from 'react';
import styles from './Header.module.scss';
import './nike-glyphs.css';

export default function Header() {
   return (
      <div className={styles.header}>
         <div className={styles.ncssRow}>
            <div className={`ncss-col-lg-4 ${styles['u-va-t']}`}>
               <i className={`g72-swoosh ${styles.logo}`}></i>
               <h2 className={styles.title}>
                  Core SRE Reports
               </h2>
            </div>
         </div>
      </div>
   );
}
