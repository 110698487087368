import React, { Suspense, lazy } from 'react';
import moment from 'moment';
import styles from './DataTableBody.module.scss';
// import ServerDropdown from 'components/ServerDropdown';

// import ServerDropdown_ACM from 'components/ServerDropdown_ACM';

const rgbaToString = color => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

const AWSAccountNameToNumber = AWSAccountName => {
   const awsAccountMap = {
      'commerce-prod'     : '538734628834',
      'commerce-test'     : '233367263614',
      'coresre-test'      : '808036060096',
      'plus'              : '218741990574',
      'plus-test'         : '083124926037',
      'shared-prod'       : '433719404598',
      'shared-test'       : '107274433934',
      'user-services-prod': '742617195046',
      'user-services-test': '751689567599',
   };

   return awsAccountMap[AWSAccountName] || `${AWSAccountName} not found`;
}

function getBackgroundColor({ cell, expirationRange, expirationColors}) {
   const {highAlertRange, mediumAlertRange, lowAlertRange, alertMetric} = expirationRange;

   const name = cell.props.cell.column.id;
   if (name === 'Expiration') {
      const timeValue = moment(cell.props.cell.value);

      const highAlertEndRange = moment().add(highAlertRange, alertMetric);
      const mediumAlertEndRange = moment().add(mediumAlertRange, alertMetric);
      const lowAlertEndRange = moment().add(lowAlertRange, alertMetric);

      const isHighAlert = moment(timeValue).isBefore(highAlertEndRange);
      const isMediumAlert = moment(timeValue).isBetween(highAlertEndRange, mediumAlertEndRange, '[]');
      const isLowAlert = moment(timeValue).isBetween(mediumAlertEndRange, lowAlertEndRange, '[]');
      // console.log(isHighAlert, isMediumAlert)

      if (isHighAlert) {
         return expirationColors.highAlertRange;
      }
      else if (isMediumAlert) {
         return expirationColors.mediumAlertRange;
      }
      else if (isLowAlert) {
         return expirationColors.lowAlertRange;
      }

   }
   return styles.box;
}

export default function DataTableBody({rows, prepareRow, serverDropdownType, serverDropdownText, expirationRange, expirationColorsHook: [expirationColors, setExpirationColors] }) {

   return (
      <tbody>
         {rows.map(
            (row, i) =>
            prepareRow(row) || (
               <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                     // console.log('cell render = ', cell.render('Cell'))
                     let cellRender = cell.render('Cell');
                     const { value } = cellRender.props.cell;

                     const header = cellRender.props.cell.column.id;

                     if ( (header === 'ELBs' || header === 'InUseBy') && value && value.length > 0) {

                        const { AWSAccount } = cellRender.props.cell.row.original;
                        const serverData = {
                           serverList: value,
                           AWSAccount: AWSAccountNameToNumber(AWSAccount),
                        };

                        const ServerDropdown = lazy(() => import('components/ServerDropdown'));

                        cellRender = (
                           <ServerDropdown
                              serverData={serverData}
                              serverDropdownText={serverDropdownText}
                              serverDropdownType={serverDropdownType}
                           />
                        )
                     }

                     const backgroundColor = {cell: cell.render('Cell'), expirationRange, expirationColors}
                        |> getBackgroundColor
                        |> rgbaToString;

                     return (
                        <td
                           {...cell.getCellProps()}
                           className={styles.box} style={{backgroundColor}}>
                           <Suspense fallback={<div>...Loading</div>}>
                              {cellRender}
                           </Suspense>
                        </td>
                     )

                  })}
                  <td></td>{/* allows color continuation for the entire row when headers are turned off*/}
               </tr>
            )
         )}
      </tbody>
   )
}
