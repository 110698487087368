import React, { useState } from 'react';
import styles from './ExpirationRangeInput.module.scss';
import './ExpirationRangeInput.css';
import MenuItem from '@material-ui/core/MenuItem';

const rgbaToString = color => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

export default function ExpirationRange(props
   // displayName,
   // fieldName,
   // onClick,
   // expirationRangeHook: [expirationRange, setExpirationRange],
   // expirationColorsHook: [expirationColors, setExpirationColors]
) {
   const {
      displayName,
      expirationField,
      onClick,
      expirationRangeHook: [expirationRange, setExpirationRange],
      expirationColorsHook: [expirationColors, setExpirationColors]
   } = props;

   // const [internalState, setInternalState] = useState({ expirationRange, expirationColors }); //creates internal state because context menu not updating when props change

   const rangeOnChangeHandler = e => {
      setExpirationRange({...expirationRange, [expirationField]: parseInt(e.target.value)});

      // setInternalState({ ...internalState,
      //    expirationRange: {...expirationRange,
      //       [expirationField]: parseInt(e.target.value)
      //    }
      // });
   }

   const style = {
      backgroundColor: rgbaToString(expirationColors[expirationField])
   }


   return (
         <MenuItem >
            <div className={styles.container}>
               <div onClick={onClick} className={styles.container}>
                  <div
                     className={`${styles.color} ${styles.one}`}
                     style={style}
                  />
                  <div className={styles.two}>
                     {displayName}
                  </div>
               </div>

               <input
                  className={`${styles.three} ${styles.input}`}
                  type="number"
                  value={expirationRange[expirationField]}
                  onChange={rangeOnChangeHandler}
                  onClick={() => console.log('clicked the input')}
               />
            </div>

         </MenuItem>
   )
}

                  {/* <div className={`${styles._row}`}>
                     <div onClick={onClick}>
      <div
         className={styles.colorBox}
         style={style}
      />
      <div className={styles._name}>
         {displayName}
      </div>
   </div>


</div> */}

{/* <>
   <MenuItem
   >
      <div className={`${styles.row}`}>
         <div onClick={onClick}>
            <div
               className={styles.colorBox}
               style={style}
            />
            <div className={styles.name}>
               {displayName}
            </div>
         </div>

         <input
            type="number"
            value={expirationRange[expirationField]}
            onChange={rangeOnChangeHandler}
            onClick={() => console.log('clicked the input')}
         />
      </div>
   </MenuItem>
</> */}
