import React, { useState } from 'react';
import DataTableColumnHeader from './DataTableColumnHeader.js';
import DropdownHeader from 'components/DropdownHeader';
import ColumnDropdownButton from 'components/ColumnDropdownButton';

import styles from './DataTableHeader.module.scss';

export default function DataTableHeader(props) {

   const { contextMenuAction, headerGroups, tableTitle } = props;

   //alternates css className for header background-color
   let count = 1;

   return (
      <thead onContextMenu={contextMenuAction}>
         {
            headerGroups.map(headerGroup => {

               return (
                  <tr className={styles['bottom' + count++]} key={count}>
                     {headerGroup.headers.map(column => {
                        return headerGroup.headers.length > 0 ? (
                           <DataTableColumnHeader
                              column={column}
                              className={`${column.render('Header') === tableTitle ? styles.tableTitle : styles.columnTitles}`}
                           />
                        ) : null;
                     })}
                     {/* {count !== 2 ? <DropdownHeader dropdownAction={contextMenuAction}/> : null /* count is 2 after first header row is rendered, only need dropdown for 2nd header row */}
                     {/* {count !== 2 ? <ColumnDropdownButton activeColumnsHook={activeColumnsHook}/> : null} */}
                     <th className={styles.thFiller}></th>{/* allows color continuation for the entire row when headers are turned off */}
                  </tr>
               )}
            )

         }
      </thead>
   );
}
