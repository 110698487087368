import React from 'react';
import styles from './DataRange.module.scss';

export default function DataRange({fromName, toName, unit, filters, setFilters, filterName}) {
   const isValueValid = value => {
      if (Number.isInteger(value) && value >= 0) {
         return true;
      }
      return false;
   }

   //returns upper range of possible 'to' values if value is out of the range
   const getInRangeValue = (rangeField, value) => {
      console.log('rangeField = ', rangeField, 'value = ', value)
      if (rangeField === 'to') {
         if (value > 730) {
            return 730;
         }
      }

      return value;
   }

   const onChangeHandler = (rangeField, value) => {

      console.log('rangeField = ', rangeField, 'value = ', value);

      let newestChar = value[value.length - 1];

      if (value === '') { //if field is empty, set to 0
         newestChar = 0; //value is now 0, so 0 is also the newestChar
         value = 0;
      }

      value = parseInt(value);

      if (isNaN(newestChar)) { //if newest char is not a number, then don't update
         return;
      }
      else if (isValueValid(value)) {
         console.log('value is valid')
         value = getInRangeValue(rangeField, value);

         const rangeObj = {...filters[filterName]};
         rangeObj[rangeField] = value;
         setFilters({...filters, [filterName]: rangeObj})
      }

   }

   let i = 1;

   return (
      <div className={styles.container}>
         <div className={styles.label} style={{order: i++}}>
            <div>{fromName}</div>
            <input
               placeholder={unit}
               onChange={e => onChangeHandler('from', e.target.value)}
               value={filters[filterName]['from']}
               className={styles.input}
            />
         </div>

         <div className={styles.to} style={{order: i++}}>to</div>

         <div className={styles.label} style={{order: i++}}>
            <div>{toName}</div>
            <input
               placeholder={unit}
               onChange={e => onChangeHandler('to', e.target.value)}
               value={filters[filterName]['to']}
               className={styles.input}
            />
         </div>
      </div>
   )
}
