import React, { useState, useEffect, useReducer, lazy, Suspense } from 'react';

import { useTable, useSortBy } from 'react-table';

import {
   defaultCookieOptions,
   cookieID,
   generateColumns,
   transformStateTemplateKeys,
   useInitializeCookies,
   filterData,
   removeOldCookies
} from 'components/DataTable/helperFunctions.js';

import styles from './DataTable.module.scss';

import DataTableBody from '../DataTableBody/DataTableBody.js';
import DataCheckbox from 'components/DataCheckbox';
import DataRange from 'components/DataRange';
import DataSearchBar from 'components/DataSearchBar';
import DataTableHeader from 'components/DataTableHeader';
// import useColumnContextMenu from 'components/useColumnContextMenu';
import ExpirationRangeInput from 'components/ExpirationRangeInput';
import DataTableSettings from 'components/DataTableSettings';
import DataFilterChecklistDropdown from 'components/DataFilterChecklistDropdown';

export default function DataTable(props) {

   const { tableTitle, tableID, stateTemplates, dataRangeFunction,
      columnsToDataFields, serverDropdownText,  serverDropdownType, serverUseField, saveCookies } = props;

   // const activeColumnsID = cookieID('activeColumns', tableID);
   const filtersID = cookieID('filters', tableID);
   const expirationRangeID = cookieID('expirationRange', tableID);
   const expirationColorsID = cookieID('expirationColors', tableID);

   const [cookies, setCookie] = [stateTemplates, tableID]
      |> transformStateTemplateKeys
      |> removeOldCookies
      |> useInitializeCookies;

   // load state from cookies
   // const [activeColumns, setActiveColumns] = useState(cookies[activeColumnsID]);
   const [filters, setFilters] = useState(cookies[filtersID]);
   const [expirationRange, setExpirationRange] = useState(cookies[expirationRangeID]);
   const [expirationColors, setExpirationColors] = useState(cookies[expirationColorsID]);

   // whenever user settings are changed, save settings state -> cookies
   useEffect(() => {
      if (saveCookies) {
         // setCookie(activeColumnsID, activeColumns, defaultCookieOptions);
         setCookie(filtersID, filters, defaultCookieOptions);
         setCookie(expirationRangeID, expirationRange, defaultCookieOptions);
         setCookie(expirationColorsID, expirationColors, defaultCookieOptions);
      }
   }, [
      filters, expirationRange, expirationColors,
      expirationColorsID, expirationRangeID, filtersID, //dependecies
      saveCookies, setCookie, tableID
   ])

   const {from, to} = filters.expirationRange;

   let renderData = {from, to}
      |> dataRangeFunction
      |> (unfilteredData => filterData(filters, unfilteredData, serverUseField));

   //generate column names for useTable
   const columns = generateColumns(filters.activeColumns, columnsToDataFields, tableTitle);

   // const [contextMenu, contextMenuAction] = useColumnContextMenu(
   //    activeColumns, setActiveColumns, expirationRange, setExpirationRange,
   //    expirationColors, setExpirationColors
   // );

   //generate table and context menu data
   const { getTableProps, headerGroups, rows, prepareRow } = useTable({columns, data: renderData}, useSortBy);

   let i = 1;

   return (
      <div className={styles.dataTableWrapper}>
         <div className={styles.container}>
            <DataCheckbox
               name="Only Show Certs In Use"
               filters={filters}
               setFilters={setFilters}
               filterName="isInUse"
               checked={filters.isInUse}
               style={{order: i++}}
            />
            <DataRange
               fromName="Has Expired Since"
               toName="Expiring Within"
               unit="days"
               filters={filters}
               setFilters={setFilters}
               filterName="expirationRange"
               style={{order: i++}}
            />
            <DataSearchBar
               filters={filters}
               setFilters={setFilters}
               filterName="searchValue"
               style={{order: i++}}
            />
            <DataFilterChecklistDropdown
               filters={filters}
               setFilters={setFilters}
               filterName='showAccounts'
               menuText='Filter Accounts'
               style={{order: i++}}
            />
            <DataFilterChecklistDropdown
               filters={filters}
               setFilters={setFilters}
               filterName='activeColumns'
               menuText='Toggle Columns'
               style={{order: i++}}
            />
            <DataTableSettings
               expirationRangeHook={[expirationRange, setExpirationRange]}
               expirationColorsHook={[expirationColors, setExpirationColors]}
               style={{order: i++}}
            />
         </div>

         <div className={styles.dataTable}>
            <table {...getTableProps()} className={styles.table}>
               <DataTableHeader
                  headerGroups={headerGroups}
                  tableTitle={tableTitle}
               />

               <DataTableBody
                  rows={rows}
                  prepareRow={prepareRow}
                  serverDropdownText={serverDropdownText}
                  serverDropdownType={serverDropdownType}
                  expirationRange={expirationRange}
                  expirationColorsHook={[expirationColors, setExpirationColors]}
               />

               </table>
         </div>

      </div>
   )
}
